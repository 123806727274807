import React, { lazy, Suspense } from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import useTG from '@/shared/hooks/useTG';
import "@/shared/styles/blank.scss";
import "@/shared/styles/fonts.scss";
import '@/shared/styles/main.scss';
import '@/shared/styles/animations.scss';
import { ProviderStore } from './context/ProviderStore';

const Index = lazy(() => import('@/pages/Index/Index'));
const Test = lazy(() => import('@/pages/Test/Test'));
const Registration = lazy(() => import('@/pages/Registration/Registration'));
 

function AppRouter() {
    const {WebApp} = useTG({requestWriteAccess: true});

    React.useEffect(()=>{
        WebApp?.expand();
    }, [])

    return (
        <Suspense fallback={null}>
            <ProviderStore>
                <Router>
                    <Routes>
                        <Route path="/" element={<Index />}/>
                        <Route path="/test" element={<Test />}/>
                        <Route path="/registration" element={<Registration />}/>
                    </Routes>
                </Router>
            </ProviderStore>
        </Suspense>
    );
}

export default AppRouter;
