import React from 'react'
import { useCookies } from 'react-cookie';

interface Props {
    onlyTG?: boolean;
    requestWriteAccess?: boolean;
}
/**
 * Хук для работы с телеграм miniapp логикой
 * 
 * Документация: https://core.telegram.org/bots/webapps#initializing-mini-apps
 */
const useTG = ({...props}: Props) => {
    const {onlyTG, requestWriteAccess} = {onlyTG: false, requestWriteAccess: false, ...props}

    /**
     * Telegram miniapp Interface
     * 
     * Документация: https://core.telegram.org/bots/webapps#initializing-mini-apps
    */
    // @ts-ignore
    const tg = window?.Telegram

    /**
     * Telegram WebApp Data
     * 
     * Документация: https://core.telegram.org/bots/webapps#initializing-mini-apps
    */
    // @ts-ignore
    const WebApp = window?.Telegram?.WebApp;

    /** 
     * Telegram miniapp user data
     * Документация: https://core.telegram.org/bots/webapps#webappinitdata
    */
    // @ts-ignore
    const tgUser = tg?.initDataUnsafe.user;

    const [, setCookies] = useCookies(['query', 'auth_date', 'hash']);
    
    React.useEffect(()=>{
        setCookies("query", WebApp?.initData ? WebApp.initData : '')
        setCookies("auth_date", WebApp?.initDataUnsafe.auth_date ? WebApp.initDataUnsafe.auth_date : '')
        setCookies("hash", WebApp?.initDataUnsafe.hash ? WebApp.initDataUnsafe.hash : '')
    }, [])

    React.useEffect(()=>{
        if (requestWriteAccess) {
            try {
                if (!tgUser?.allows_write_to_pm) {
                    WebApp.requestWriteAccess((isGranted: boolean) => { console.log('isGranted', isGranted); if (isGranted) { console.log('access granted') } });
                }
            } catch (error: any) {
                console.log("requestWriteAccess", error)
            }
        }
    }, [requestWriteAccess])

    return {tg, WebApp, tgUser}
}

export default useTG