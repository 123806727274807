import React from "react";
import ThemeContextProvider from "./ThemeContext";

/**
 * Точка включения всех необходимых глобальных контекстов
 * @param param0 
 * @returns 
 */
export function ProviderStore({ children }: any) {
    return (
        <ThemeContextProvider>
            {children}
        </ThemeContextProvider>
    )
}